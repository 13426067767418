import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationSettings } from 'src/app/entities/notification-settings.model';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { WeeklyAvailabilitySlot } from '../../../entities/availability-slot.model';
import { User } from '../../../entities/user.model';

import moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { ClientProfile } from 'src/app/entities/client-profile';
import { SubscriptionStatus } from '../../../entities/subscription-status.model';
import { SubscriptionBilling } from '../../../entities/subscription-billing.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private http: GenericHttpService,
    private translate: TranslateService,
    private cookies: CookieService
  ) {}

  updateCurrentUser = (user: User): Observable<{ user: User }> =>
    this.http.put('/current_user', user);

  updateClientProfile = (
    ClientProfile: ClientProfile
  ): Observable<ClientProfile> =>
    this.http.put('/client_profile/' + ClientProfile.id, ClientProfile);

  deleteUser = (user: User) => this.http.delete('/users/' + user.id);

  getAvailabilitySlots = (): Observable<any> => this.http.get('/availability');

  getClientProfile = (): Observable<ClientProfile> => {
    return this.http.get('/clients_profile');
  };

  public getTherapistAvailabilitySlots = (
    slug: string,
    start: string,
    end: string
  ): Observable<any> => this.http.post(`/availability/${slug}/${start}`, {});

  getUpcomingDateSpeceficAvailabilities = (): Observable<any> => {
    return this.http.get('/me/upcoming_date_specific_availabilities');
  };

  createDateSpecificAvailability = (availability: any): Observable<any> => {
    return this.http.post('/me/date_specific_availabilities', availability);
  };

  deleteDateSpecificAvailability = (id: number): Observable<any> => {
    return this.http.delete(`/me/date_specific_availabilities/${id}`);
  };

  deleteAvailabilitySlot = (slotID): Observable<any> =>
    this.http.delete('/availability/' + slotID);

  createAvailabilitySlot = (slot: WeeklyAvailabilitySlot): Observable<any> => {
    return this.http.post('/availability', slot);
  };

  deleteProfilePicture = (user: User): Observable<any> => {
    return this.http.delete('/profile/images');
  };

  getNotificationSettings = (profile_id): Observable<NotificationSettings[]> =>
    this.http.get(`/notification_settings/${profile_id}`);

  updateNotificationSettings = (
    notification_settings: NotificationSettings
  ): Observable<NotificationSettings[]> => {
    return this.http.put(
      `/notification_settings/${notification_settings.id}`,
      notification_settings
    );
  };

  getInvoices = (): Observable<any> => this.http.get('/subscription/invoices');

  getUpcomingInvoice = (): Observable<any> =>
    this.http.get('/subscription/invoices/upcoming');

  getStripeUser = (): Observable<any> => this.http.get('/subscription/user');

  updatePaymentMethod = (token): Observable<any> =>
    this.http.post('/subscription/updateCard', { token: token });

  updateBillingDetails = (data): Observable<any> =>
    this.http.post('/subscription/updateAddress', { userdata: data });

  redeemCoupon = (coupon): Observable<any> =>
    this.http.post('/coupon', { coupon: coupon });

  checkCoupon = (coupon): Observable<any> =>
    this.http.post('/coupon/check', { coupon: coupon });

  getCoupons = (): Observable<any> => this.http.get('/coupon');

  getPlanPickerPlans = (): Observable<any> =>
    this.http.get('/plan_picker_plans');

  getSubscriptionStatus = (): Observable<SubscriptionStatus> =>
    this.http.get('/subscription_status');

  getSubscriptionBilling = (): Observable<SubscriptionBilling> =>
    this.http.get('/subscription_billing');

  getPlanByKind = (kind): Observable<any> => this.http.get('/plans/' + kind);

  getActiveSubscriptions = (): Observable<any> =>
    this.http.get('/subscription/active');

  cancelSubscription = (): Observable<any> => this.http.delete('/subscription');

  downgradeSubscription = (): Observable<any> =>
    this.http.post('/subscription/downgrade-to-free', {});

  getDowngradeOffer = (): Observable<{
    offer: {
      id: RetentionCouponId;
    } | null;
  }> => this.http.get('/subscription/get-downgrade-offer', {});

  downgradeToBasicUsingRetentionOffer = (
    coupon_id: RetentionCouponId
  ): Observable<any> =>
    this.http.post('/subscription/downgrade-to-basic-using-retention-offer', {
      coupon_id,
    });

  upgradeToPremiumUsingRetentionOffer = (): Observable<any> =>
    this.http.post(
      '/subscription/upgrade-to-premium-using-retention-offer',
      {}
    );

  previewUpgradeToPremiumUsingRetentionOffer = (): Observable<any> =>
    this.http.get(
      '/subscription/preview_upgrade-to-premium-using-retention-offer'
    );

  cancelScheduledSubscriptionChange = (): Observable<any> =>
    this.http.post('/subscription/cancel-scheduled-change', {});

  useLanguage(locale: string) {
    if (locale == 'de' || locale == 'en') {
      this.translate.setDefaultLang(locale);
      this.cookies.put('iclocale', locale, {
        expires: moment().add(1, 'month').toDate(),
      });
    }
  }
  setLanguageOnStartup() {
    var locale = this.cookies.get('iclocale');
    if (locale) {
      this.useLanguage(locale);
    }
  }
}

export type RetentionCouponId =
  | 'offer_basic_6'
  | 'offer_basic_6_low'
  | 'offer_basic_3';
