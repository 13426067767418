import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientProfile } from '../entities/client-profile';
import { Connection } from '../entities/connection.model';
import { GenericHttpService } from '../shared/services/Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileService {
  constructor(private http: GenericHttpService) {}

  private profileSource = new BehaviorSubject<ClientProfile>(null);
  profile = this.profileSource.asObservable();

  private therapistSource = new BehaviorSubject<Connection>(null);
  therapist = this.therapistSource.asObservable();

  private therapistsSource = new BehaviorSubject<Array<Connection>>(null);
  therapists = this.therapistsSource.asObservable();

  loadClientProfile() {
    this.http
      .get('/clientprofile/profile')
      .subscribe((profile) => this.profileSource.next(profile));
  }

  updateClientProfile(profile: ClientProfile) {
    this.http
      .put('/clientprofile/profile/' + profile.id, profile)
      .subscribe((response) => {
        this.profileSource.next(response);
      });
  }

  updateConnection(therapist: Connection) {
    this.http
      .put('/clientprofile/connection/' + therapist.uid, therapist)
      .subscribe((response) => {
        this.therapistsSource.next(response);
      });
  }

  loadTherapists() {
    return this.http.get('/clientprofile/therapists');
  }

  loadTherapist(therapistUserID: number) {
    return this.http.get('/clientprofile/therapists/' + therapistUserID);
  }

  getGeocode = (location): Observable<any> =>
    this.http.get('/geocode?location=' + location);
}
